import React, { Component } from 'react';

function playAudio(id) {
  const audioEl = document.getElementById(id);
  const container = document.getElementById(id + '-container');
  if (audioEl.paused) {
    container.classList.add('playing');
    audioEl.play();
  } else {
    container.classList.remove('playing');
    audioEl.pause();
  }
  var audios = document.getElementsByTagName('audio');
  document.addEventListener(
    'play',
    function(e) {
      for (var i = 0, len = audios.length; i < len; i++) {
        if (audios[i] != e.target) {
          audios[i].pause();
          audios[i].parentElement.classList.remove('playing');
        }
      }
    },
    true
  );
}

export default class extends Component {
  render() {
    return (
      <div
        className="playContainer"
        id={this.props.id + '-container'}
        onClick={() => playAudio(this.props.id)}
      >
        <button className="play"></button>
        <audio className="audio-element" id={this.props.id}>
          <source src={this.props.mp3}></source>
        </audio>
      </div>
    );
  }
}
