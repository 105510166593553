import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Textbox from '../text/Textbox';
import { graphql } from 'gatsby';
import Audio from '../audio/Audio';
import Helmet from 'react-helmet';

const MediaDetails = styled.h3`
  font-size: 28px;

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const MediaTitle = styled.div`
  text-align: center;

  color: #000;

  padding: 30px 0 0 0;
  span {
    color: red;
  }
  font-size: 28px;
  & h1 {
    display: inline;
  }
  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const TextContainer = styled.div`
  max-width: 655px;
  text-align: left;

  margin: ${props => props.margin || '0 auto'};

  font-size: 28px;

  a {
    color: red;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const MediaContainer = styled.div`
  margin-bottom: 40px;
`;

const BuyButton = styled.div`
  line-height: 1;
  -webkit-appearance: none;

  & input[type='submit'],
  & a {
    -webkit-appearance: none;
    margin: -4px 0 -4px 47px;
    padding: 6px 9px 6px 8px;
    text-decoration: none;
    line-height: 1;
    color: #000;
    background-color: #fff;
    border: 2px solid #000;
    cursor: pointer;
    border-radius: 0px;
    &:focus {
      outline: none;
    }
    &:hover {
      color: #fff;
      background-color: #000;

      border: 2px solid rgba(255, 255, 255, 0);
    }
  }
`;

const Media = ({ data: { prismicMedia } }) => {
  const { data } = prismicMedia;

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = window.Plyr;
      Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));
    }
  });

  let dimensions =
    data.image.dimensions !== null ? data.image.dimensions : null;
  let imageWidth = dimensions !== null ? dimensions.width : null;
  let imageHeight = dimensions !== null ? dimensions.height : null;

  const media =
    data.type === 'Video' ? (
      <Col col={12}>
        <Row justifyContent="center">
          <Col col={12} md={8}>
            <div
              className="js-player"
              data-plyr-provider="vimeo"
              data-plyr-config='{ "ratio": "16:9" }'
              data-plyr-embed-id={data.vimeo_id}
            ></div>
          </Col>
          <Col col={12}>
            <MediaContainer>
              <MediaTitle
                dangerouslySetInnerHTML={{
                  __html: '<span>Watch </span>' + data.title.html,
                }}
              ></MediaTitle>
              <Textbox
                margin={`20px auto`}
                mobileMargin={`26px auto 20px auto`}
                textAlign={`left`}
                text={data.text.html}
              />
            </MediaContainer>
          </Col>
        </Row>
      </Col>
    ) : (
      <Col col={12}>
        <Row justifyContent="center">
          <Col col={12} md={8}>
            <div>
              <figure
                className="audio"
                style={{ position: 'relative', margin: 0 }}
              >
                {imageWidth > imageHeight ? (
                  <img
                    src={data.image.url}
                    loading={'auto'}
                    alt={data.image.alt}
                  />
                ) : (
                  <img
                    src={data.image.url}
                    loading={'auto'}
                    alt={data.image.alt}
                  />
                )}
                <Audio mp3={data.audiourl.url} id={'audio-' + data.id}></Audio>
              </figure>
              <MediaTitle>
                <h2>
                  <span>Listen </span>
                  {data.title.text}
                </h2>
                <Textbox
                  margin={`20px auto`}
                  mobileMargin={`26px auto 20px auto`}
                  textAlign={`left`}
                  text={data.text.html}
                />
              </MediaTitle>
            </div>
          </Col>
        </Row>
      </Col>
    );
  return (
    <>
      <Helmet
        title={`${
          data.title.text !== '' ? data.title.text + '-' : ''
        }  Askeaton Contemporary Arts`}
      />
      {media}
    </>
  );
};

export default Media;

export const pageQuery = graphql`
  query MediaBySlug($uid: String!) {
    prismicMedia(uid: { eq: $uid }) {
      uid
      id
      data {
        text {
          html
        }
        title {
          text
          html
        }
        image {
          url
          dimensions {
            width
            height
          }
        }
        audiourl {
          url
        }
        type
        vimeo_id
      }
    }
  }
`;
